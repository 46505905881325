// src/components/Hero.js
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import heroImage from '../assets/images/hero.jpg'; // Ensure you have a hero image
import './Hero.css'; // Optional: Create a separate CSS file for additional styling

const Hero = () => {
  return (
    <div
      className="hero-section"
      style={{ backgroundImage: `url(${heroImage})` }}
      data-aos="fade-in"
    >
      <Container>
        <h1 className="display-4">Expert CRNA Staffing Solutions</h1>
        <p className="lead">
          Connecting top Certified Registered Nurse Anesthetists with leading healthcare facilities.
        </p>
        <Button variant="primary" href="/contact">Get Started</Button>
      </Container>
    </div>
  );
};

export default Hero;
