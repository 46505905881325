// src/components/About.js
import React from 'react';
import { Container, Row, Col, /* Image */ } from 'react-bootstrap';
// import aboutImage from '../assets/images/about.png'; // Ensure you have an about image

const About = () => {
  return (
    <Container className="my-5">
      <h2 className="text-center mb-4" data-aos="fade-up">About RxRooster</h2>
      <Row>
        {/* <Col md={6} data-aos="fade-right">
          <Image src={aboutImage} fluid rounded />
        </Col> */}
        <Col md={12} data-aos="fade-left">
          <p>
            RxRooster is a leading Certified Registered Nurse Anesthetist (CRNA) staffing company dedicated to connecting top-tier CRNAs with hospitals and healthcare facilities across the nation. Our mission is to ensure quality patient care by providing highly skilled anesthetists who seamlessly integrate into your teams.
          </p>
          <p>
            With years of experience in the healthcare staffing industry, RxRooster understands the critical role CRNAs play in surgical and medical procedures. We are committed to delivering personalized staffing solutions tailored to your facility’s unique needs.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
