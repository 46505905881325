// src/components/Features.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import feature1 from '../assets/images/feature1.jpg'; // Ensure you have feature images
import feature2 from '../assets/images/feature2.png';
import feature3 from '../assets/images/feature3.png';

const featuresData = [
  {
    image: feature1,
    title: 'Comprehensive Staffing',
    description: 'We provide fully credentialed CRNAs tailored to your facility’s specific needs.',
  },
  {
    image: feature2,
    title: 'Flexible Contracts',
    description: 'Short-term, long-term, or per diem – we offer flexible staffing solutions.',
  },
  {
    image: feature3,
    title: '24/7 Support',
    description: 'Our team is available around the clock to assist with any staffing requirements.',
  },
];

const Features = () => {
  return (
    <Container className="my-5">
      <h2 className="text-center mb-4" data-aos="fade-up">Our Services</h2>
      <Row>
        {featuresData.map((feature, index) => (
          <Col md={4} key={index} className="d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={index * 100}>
            <Card className="mb-4 text-center">
              {/* <Card.Img variant="top" src={feature.image} style={{ width: '100px', height: '100px', objectFit: 'contain', margin: '20px auto 0' }} /> */}
              <Card.Body>
                <Card.Title>{feature.title}</Card.Title>
                <Card.Text>{feature.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Features;
