// src/pages/ServicesPage.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import service1 from '../assets/images/service1.png'; // Ensure you have service images
import service2 from '../assets/images/service2.png';
import service3 from '../assets/images/service3.png';

const servicesData = [
  {
    image: service1,
    title: 'Temporary Staffing',
    description: 'Quickly fill temporary CRNA positions to cover for leaves, vacations, or peak periods.',
  },
  {
    image: service2,
    title: 'Permanent Placement',
    description: 'Assist in finding permanent CRNA staff that fit your facility’s culture and requirements.',
  },
  {
    image: service3,
    title: 'Emergency Staffing',
    description: 'Provide immediate CRNA support in emergency situations to ensure uninterrupted patient care.',
  },
];

const ServicesPage = () => {
  return (
    <Container className="my-5">
      <h2 className="text-center mb-4" data-aos="fade-up">Our Services</h2>
      <Row>
        {servicesData.map((service, index) => (
          <Col md={4} key={index} className="d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={index * 100}>
            <Card className="mb-4 text-center">
              {/* <Card.Img variant="top" src={service.image} style={{ width: '100px', height: '100px', objectFit: 'contain', margin: '20px auto 0' }} /> */}
              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ServicesPage;
