// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-light py-4">
      <Container>
        <Row>
          <Col md={6} data-aos="fade-up">
            <h5>RxRooster</h5>
            <p>
              Providing expert Certified Registered Nurse Anesthetist (CRNA) staffing solutions to healthcare facilities nationwide.
            </p>
            <p>
              &copy; {new Date().getFullYear()} RxRooster. All rights reserved.
            </p>
          </Col>
          <Col md={6} className="text-md-end" data-aos="fade-up" data-aos-delay="100">
            <h5>Follow Us</h5>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-dark mx-2">
              <FaFacebook size={24} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-dark mx-2">
              <FaTwitter size={24} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-dark mx-2">
              <FaLinkedin size={24} />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
