// src/pages/Home.js
import React from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import About from '../components/About';
// import Testimonials from '../components/Testimonials';
import ContactForm from '../components/ContactForm';

const Home = () => {
  return (
    <>
      <Hero />
      <Features />
      <About />
      {/* <Testimonials /> */}
      <ContactForm />
    </>
  );
};

export default Home;
